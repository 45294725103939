import isFunction from 'lodash/isFunction';
import flowRight from 'lodash/flowRight';

export const LOAD_PROPERTIES = 'redux-ducks/settings/LOAD';
export const LOAD_PROPERTIES_SUCCESS = 'redux-ducks/settings/LOAD_SUCCESS';
export const LOAD_PROPERTIES_FAIL = 'redux-ducks/settings/LOAD_FAIL';

export const ADD_PROPERTY = 'redux-ducks/settings/ADD_PROPERTY';
export const ADD_PROPERTY_SUCCESS = 'redux-ducks/settings/ADD_PROPERTY_SUCCESS';
export const ADD_PROPERTY_FAIL = 'redux-ducks/settings/ADD_PROPERTY_FAIL';

export const UPDATE_PROPERTY = 'redux-ducks/settings/UPDATE_PROPERTY';
export const UPDATE_PROPERTY_SUCCESS = 'redux-ducks/settings/UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_FAIL = 'redux-ducks/settings/UPDATE_PROPERTY_FAIL';

export const UPDATE_PROPERTY_POSITION = 'redux-ducks/settings/UPDATE_PROPERTY_POSITION';
export const UPDATE_PROPERTY_POSITION_SUCCESS = 'redux-ducks/settings/UPDATE_PROPERTY_POSITION_SUCCESS';
export const UPDATE_PROPERTY_POSITION_FAIL = 'redux-ducks/settings/UPDATE_PROPERTY_POSITION_FAIL';

export const REMOVE_PROPERTY = 'redux-ducks/settings/REMOVE_PROPERTY';
export const REMOVE_PROPERTY_SUCCESS = 'redux-ducks/settings/REMOVE_PROPERTY_SUCCESS';
export const REMOVE_PROPERTY_FAIL = 'redux-ducks/settings/REMOVE_PROPERTY_FAIL';

const LOAD_SHIFT_TYPES = 'redux-ducks/settings/LOAD_SHIFT_TYPES';
const LOAD_SHIFT_TYPES_SUCCESS = 'redux-ducks/settings/LOAD_SHIFT_TYPES_SUCCESS';
const LOAD_SHIFT_TYPES_FAIL = 'redux-ducks/settings/LOAD_SHIFT_TYPES_FAIL';

const CREATE_SHIFT_TYPE = 'redux-ducks/settings/CREATE_SHIFT_TYPE';
const CREATE_SHIFT_TYPE_SUCCESS = 'redux-ducks/settings/CREATE_SHIFT_TYPE_SUCCESS';
const CREATE_SHIFT_TYPE_FAIL = 'redux-ducks/settings/CREATE_SHIFT_TYPE_FAIL';

const UPDATE_SHIFT_TYPE = 'redux-ducks/settings/UPDATE_SHIFT_TYPE';
const UPDATE_SHIFT_TYPE_SUCCESS = 'redux-ducks/settings/UPDATE_SHIFT_TYPE_SUCCESS';
const UPDATE_SHIFT_TYPE_FAIL = 'redux-ducks/settings/UPDATE_SHIFT_TYPE_FAIL';

const DELETE_SHIFT_TYPE = 'redux-ducks/settings/DELETE_SHIFT_TYPE';
const DELETE_SHIFT_TYPE_SUCCESS = 'redux-ducks/settings/DELETE_SHIFT_TYPE_SUCCESS';
const DELETE_SHIFT_TYPE_FAIL = 'redux-ducks/settings/DELETE_SHIFT_TYPE_FAIL';

export const SET_STATUS_PAGE = 'redux-ducks/settings/SET_STATUS_PAGE';
export const SET_DEFAULT = 'redux-ducks/settings/SET_DEFAULT';

export const LOAD_EXPENSES = 'redux-ducks/settings/LOAD_EXPENSES';
export const LOAD_EXPENSES_SUCCESS = 'redux-ducks/settings/LOAD_EXPENSES_SUCCESS';
export const LOAD_EXPENSES_FAIL = 'redux-ducks/settings/LOAD_EXPENSES_FAIL';

export const UPDATE_EXPENSES = 'redux-ducks/settings/UPDATE_EXPENSES';
export const UPDATE_EXPENSES_SUCCESS = 'redux-ducks/settings/UPDATE_EXPENSES_SUCCESS';
export const UPDATE_EXPENSES_FAIL = 'redux-ducks/settings/UPDATE_EXPENSES_FAIL';

const LOAD_REMOVAL_REASONS = 'redux-ducks/settings/LOAD_REMOVAL_REASONS';
const LOAD_REMOVAL_REASONS_SUCCESS = 'redux-ducks/settings/LOAD_REMOVAL_REASONS_SUCCESS';
const LOAD_REMOVAL_REASONS_FAIL = 'redux-ducks/settings/LOAD_REMOVAL_REASONS_FAIL';

const ADD_REMOVAL_REASON = 'redux-ducks/settings/ADD_REMOVAL_REASON';
const ADD_REMOVAL_REASON_SUCCESS = 'redux-ducks/settings/ADD_REMOVAL_REASON_SUCCESS';
const ADD_REMOVAL_REASON_FAIL = 'redux-ducks/settings/ADD_REMOVAL_REASON_FAIL';

const UPDATE_REMOVAL_REASON = 'redux-ducks/settings/UPDATE_REMOVAL_REASON';
const UPDATE_REMOVAL_REASON_SUCCESS = 'redux-ducks/settings/UPDATE_REMOVAL_REASON_SUCCESS';
const UPDATE_REMOVAL_REASON_FAIL = 'redux-ducks/settings/UPDATE_REMOVAL_REASON_FAIL';

const DELETE_REMOVAL_REASON = 'redux-ducks/settings/DELETE_REMOVAL_REASON';
const DELETE_REMOVAL_REASON_SUCCESS = 'redux-ducks/settings/REMOVE_REMOVAL_REASON_SUCCESS';
const DELETE_REMOVAL_REASON_FAIL = 'redux-ducks/settings/REMOVE_REMOVAL_REASON_FAIL';

const LOAD_FOOTER = 'redux-ducks/settings/LOAD_FOOTER';
const LOAD_FOOTER_SUCCESS = 'redux-ducks/settings/LOAD_FOOTER_SUCCESS';
const LOAD_FOOTER_FAIL = 'redux-ducks/settings/LOAD_FOOTER_FAIL';

const UPDATE_FOOTER = 'redux-ducks/settings/UPDATE_FOOTER';
const UPDATE_FOOTER_SUCCESS = 'redux-ducks/settings/UPDATE_FOOTER_SUCCESS';
const UPDATE_FOOTER_FAIL = 'redux-ducks/settings/UPDATE_FOOTER_FAIL';

const LOAD_SDB_CATEGORIES = 'redux-ducks/settings/LOAD_SDB_CATEGORIES';
const LOAD_SDB_CATEGORIES_SUCCESS = 'redux-ducks/settings/LOAD_SDB_CATEGORIES_SUCCESS';
const LOAD_SDB_CATEGORIES_FAIL = 'redux-ducks/settings/LOAD_SDB_CATEGORIES_FAIL';

const UPDATE_SDB_CATEGORIES = 'redux-ducks/settings/UPDATE_SDB_CATEGORIES';
const UPDATE_SDB_CATEGORIES_SUCCESS = 'redux-ducks/settings/UPDATE_SDB_CATEGORIES_SUCCESS';
const UPDATE_SDB_CATEGORIES_FAIL = 'redux-ducks/settings/UPDATE_SDB_CATEGORIES_FAIL';

const LOAD_HOURS_RESTRICTION = 'redux-ducks/settings/LOAD_HOURS_RESTRICTION';
const LOAD_HOURS_RESTRICTION_SUCCESS = 'redux-ducks/settings/LOAD_HOURS_RESTRICTION_SUCCESS';
const LOAD_HOURS_RESTRICTION_FAIL = 'redux-ducks/settings/LOAD_HOURS_RESTRICTION_FAIL';

const UPDATE_HOURS_RESTRICTION = 'redux-ducks/settings/UPDATE_HOURS_RESTRICTION';
const UPDATE_HOURS_RESTRICTION_SUCCESS = 'redux-ducks/settings/UPDATE_HOURS_RESTRICTION_SUCCESS';
const UPDATE_HOURS_RESTRICTION_FAIL = 'redux-ducks/settings/UPDATE_HOURS_RESTRICTION_FAIL';

const LOAD_TAGS = 'redux-ducks/settings/LOAD_TAGS';
const LOAD_TAGS_SUCCESS = 'redux-ducks/settings/LOAD_TAGS_SUCCESS';
const LOAD_TAGS_FAIL = 'redux-ducks/settings/LOAD_TAGS_FAIL';

const CREATE_TAG = 'redux-ducks/settings/UPDATE_TAG';
const CREATE_TAG_SUCCESS = 'redux-ducks/settings/UPDATE_TAG_SUCCESS';
const CREATE_TAG_FAIL = 'redux-ducks/settings/UPDATE_TAG_FAIL';

const UPDATE_TAG = 'redux-ducks/settings/UPDATE_TAG';
const UPDATE_TAG_SUCCESS = 'redux-ducks/settings/UPDATE_TAG_SUCCESS';
const UPDATE_TAG_FAIL = 'redux-ducks/settings/UPDATE_TAG_FAIL';

const DELETE_TAG = 'redux-ducks/settings/DELETE_TAG';
const DELETE_TAG_SUCCESS = 'redux-ducks/settings/DELETE_TAG_SUCCESS';
const DELETE_TAG_FAIL = 'redux-ducks/settings/DELETE_TAG_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  listExpenses: [],
  removalReasons: [],
  tags: [],
  entity: {
    worker: [],
    gig: [],
    organization: [],
    byId: {},
  },
  entityShift: {
    byId: {},
    allIds: []
  },
  error: null,
};

// Actions
/**
 * Load expenses from BD
 * @param {string} type
 */
export const loadExpenses = (loc) => ({
  types: [LOAD_EXPENSES, LOAD_EXPENSES_SUCCESS, LOAD_EXPENSES_FAIL],
  promise: (client) => client.get(`v3/planners/expense_types?locale=${loc}`),
});

export const updateExpenses = (data) => ({
  types: [UPDATE_EXPENSES, UPDATE_EXPENSES_SUCCESS, UPDATE_EXPENSES_FAIL],
  promise: (client) => client.patch(`v3/planners/expense_types/${data.id}`, { data }),
});

/**
 * Load properties from BD
 * @param {string} type
 */
export const loadProperties = (type = 'gig') => ({
  types: [LOAD_PROPERTIES, LOAD_PROPERTIES_SUCCESS, LOAD_PROPERTIES_FAIL],
  promise: (client) => client.get(`v3/planners/custom_attributes?by_type=${type}`),
  attribute: type,
});

/**
 * Save property in BD
 * @param {object} data property
 */
export const addProperty = (data) => ({
  types: [ADD_PROPERTY, ADD_PROPERTY_SUCCESS, ADD_PROPERTY_FAIL],
  promise: (client) => client.post('v3/planners/custom_attributes', { data }),
  attribute: data.custom_attribute.type,
});

/**
 * Update property in BD, by property id
 * @param {object} data property
 */
export const updateProperty = (data) => ({
  types: [UPDATE_PROPERTY, UPDATE_PROPERTY_SUCCESS, UPDATE_PROPERTY_FAIL],
  promise: (client) => client.patch(`v3/planners/custom_attributes/${data.id}`, { data })
});

/**
 * Update properties position in BD
 * @param {object} data
 */
export const updatePropertyPosition = (data) => ({
  types: [UPDATE_PROPERTY_POSITION, UPDATE_PROPERTY_POSITION_SUCCESS, UPDATE_PROPERTY_POSITION_FAIL],
  promise: (client) => client.patch('v3/planners/custom_attributes/positions_update', { data }),
  payload: data,
});

/**
 * Delete property in BD, by property id
 */
export const removeProperty = (item) => ({
  types: [REMOVE_PROPERTY, REMOVE_PROPERTY_SUCCESS, REMOVE_PROPERTY_FAIL],
  promise: (client) => client.del(`v3/planners/custom_attributes/${item.id}`),
  property: item,
});

/**
 * Load shift types
 */
export const loadShiftTypes = () => ({
  types: [LOAD_SHIFT_TYPES, LOAD_SHIFT_TYPES_SUCCESS, LOAD_SHIFT_TYPES_FAIL],
  promise: (client) => client.get('v3/planners/gig_categories'),
});
/**
 * Create shift type
 * @param {object} data Item
 */
export const createShiftType = (data) => ({
  types: [CREATE_SHIFT_TYPE, CREATE_SHIFT_TYPE_SUCCESS, CREATE_SHIFT_TYPE_FAIL],
  promise: (client) => client.post('v3/planners/gig_categories', { data }),
});
/**
 * Update shift type by id
 * @param {object} data Item
 */
export const updateShiftType = (data) => ({
  types: [UPDATE_SHIFT_TYPE, UPDATE_SHIFT_TYPE_SUCCESS, UPDATE_SHIFT_TYPE_FAIL],
  promise: (client) => client.patch(`v3/planners/gig_categories/${data.id}`, { data }),
});
/**
 * Delete shift type by id
 * @param {number} id Item id
 */
export const deleteShiftType = (id) => ({
  types: [DELETE_SHIFT_TYPE, DELETE_SHIFT_TYPE_SUCCESS, DELETE_SHIFT_TYPE_FAIL],
  promise: (client) => client.del(`v3/planners/gig_categories/${id}`),
  id,
});

/**
 * Reasons why removal shift
 */

export const loadRemovalReasons = () => ({
  types: [LOAD_REMOVAL_REASONS, LOAD_REMOVAL_REASONS_SUCCESS, LOAD_REMOVAL_REASONS_FAIL],
  promise: (client) => client.get('v3/planners/removal_reason_templates')
});

export const addRemovalReason = (data) => ({
  types: [ADD_REMOVAL_REASON, ADD_REMOVAL_REASON_SUCCESS, ADD_REMOVAL_REASON_FAIL],
  promise: (client) => client.post('v3/planners/removal_reason_templates', { data })
});

export const updateRemovalReason = (data) => ({
  types: [UPDATE_REMOVAL_REASON, UPDATE_REMOVAL_REASON_SUCCESS, UPDATE_REMOVAL_REASON_FAIL],
  promise: (client) => client.patch(`v3/planners/removal_reason_templates/${data.id}`, { data })
});

export const deleteRemovalReason = (id) => ({
  types: [DELETE_REMOVAL_REASON, DELETE_REMOVAL_REASON_SUCCESS, DELETE_REMOVAL_REASON_FAIL],
  promise: (client) => client.del(`v3/planners/removal_reason_templates/${id}`),
  id
});

/**
 * Invoice footer
 */

export const loadFooter = (data) => ({
  types: [LOAD_FOOTER, LOAD_FOOTER_SUCCESS, LOAD_FOOTER_FAIL],
  promise: (client) => client
    .get(`v3/planners/invoice_footer_templates/${data.invoice_type}?organizations_group_id=${data.id}`, { data })
});

export const updateFooter = (data) => ({
  types: [UPDATE_FOOTER, UPDATE_FOOTER_SUCCESS, UPDATE_FOOTER_FAIL],
  promise: (client) => client.patch(`v3/planners/invoice_footer_templates/${data.invoice_type}`, { data })
});

/**
 * SDB settings
 */
export const loadSDBCategories = () => ({
  types: [LOAD_SDB_CATEGORIES, LOAD_SDB_CATEGORIES_SUCCESS, LOAD_SDB_CATEGORIES_FAIL],
  promise: (client) => client.get('v3/planners/sdb_categories?include=gig_category')
});

export const updateSDBCategories = (data) => ({
  types: [UPDATE_SDB_CATEGORIES, UPDATE_SDB_CATEGORIES_SUCCESS, UPDATE_SDB_CATEGORIES_FAIL],
  promise: (client) => client.patch(`v3/planners/sdb_categories/${data.id}`, { data })
});

export const loadHoursRestriction = () => ({
  types: [LOAD_HOURS_RESTRICTION, LOAD_HOURS_RESTRICTION_SUCCESS, LOAD_HOURS_RESTRICTION_FAIL],
  promise: (client) => client.get('v3/planners/work_hours_restriction')
});

export const updateHoursRestriction = (data) => ({
  types: [UPDATE_HOURS_RESTRICTION, UPDATE_HOURS_RESTRICTION_SUCCESS, UPDATE_HOURS_RESTRICTION_FAIL],
  promise: (client) => client.patch('v3/planners/work_hours_restriction', { data })
});

/**
 * Tags settings
 */
export const loadTags = () => ({
  types: [LOAD_TAGS, LOAD_TAGS_SUCCESS, LOAD_TAGS_FAIL],
  promise: (client) => client.get('v3/planners/tags')
});

export const createTag = (data) => ({
  types: [CREATE_TAG, CREATE_TAG_SUCCESS, CREATE_TAG_FAIL],
  promise: (client) => client.post('v3/planners/tags', { data })
});
export const updateTag = (data) => ({
  types: [UPDATE_TAG, UPDATE_TAG_SUCCESS, UPDATE_TAG_FAIL],
  promise: (client) => client.patch(`v3/planners/tags/${data.id}`, { data })
});
export const deleteTag = (id) => ({
  types: [DELETE_TAG, DELETE_TAG_SUCCESS, DELETE_TAG_FAIL],
  promise: (client) => client.del(`v3/planners/tags/${id}`)
});

/**
 * Set default state
 */
export const setDefault = () => ({ type: SET_DEFAULT });

//
// Mutations
//
const updateRequestOnLoad = (value) => (state) => ({
  ...state,
  loading: value,
});

const updateRequestOnLoadSuccess = (value) => (state) => ({
  ...state,
  loading: value,
  loaded: true,
  error: null,
});

/**
 * Save data in store
 * @param {object} action response from API
 */
const updateSettingsOnLoad = (action) => (state) => {
  const { byId } = state.entity;
  const { data } = action.result;

  const updId = data.sort((a, b) => (a.attributes.position - b.attributes.position))
    .map((item) => +item.id);
  const normalize = {};

  data.forEach((item) => {
    normalize[item.id] = {
      ...item.attributes,
    };
  });

  return ({
    ...state,
    entity: {
      ...state.entity,
      [action.attribute]: updId,
      byId: {
        ...byId,
        ...normalize,
      }
    },
  });
};

/**
 * Add property to store
 * @param {Object} action response from API
 */
const addPropertySuccess = (action) => (state) => {
  const { data } = action.result;
  const attribute = data.attributes;

  return ({
    ...state,
    entity: {
      ...state.entity,
      [action.attribute]: [
        ...state.entity[action.attribute],
        attribute.id,
      ],
      byId: {
        ...state.entity.byId,
        [attribute.id]: attribute,
      }
    },
  });
};

const updatePropertySuccess = (action) => (state) => {
  const { data } = action.result;
  const attribute = data.attributes;

  return ({
    ...state,
    entity: {
      ...state.entity,
      byId: {
        ...state.entity.byId,
        [attribute.id]: attribute,
      }
    },
  });
};

const removePropertySuccess = (action) => (state) => {
  const { byId } = state.entity;
  const { property } = action;
  const filteredAllIds = state.entity[property.type].filter((id) => id !== property.id);

  const updatedById = { ...byId };
  delete updatedById[property.id];

  return ({
    ...state,
    entity: {
      ...state.entity,
      [property.type]: filteredAllIds,
      byId: updatedById,
    },
  });
};

const updatePositionPropertySuccess = (action) => (state) => {
  const { byId } = state.entity;
  const positionsData = action.payload.custom_attribute.positions_data;

  positionsData.forEach((item) => {
    byId[item.id].position = item.position;
  });

  return ({
    ...state,
    entity: {
      ...state.entity,
      byId,
    },
  });
};

const updateRequestOnLoadFail = (action, value) => (state) => ({
  ...state,
  loading: value,
  loaded: value,
  entity: {
    worker: [],
    gig: [],
    organization: [],
    byId: {},
  },
  error: action.error
});

const loadShiftTypesSuccess = (action) => (state) => {
  const { byId } = state.entity;
  const { data } = action.result;

  const updId = data.map((item) => +item.id);
  const normalize = {};

  data.forEach((item) => {
    normalize[item.id] = {
      ...item.attributes,
    };
  });

  return ({
    ...state,
    entityShift: {
      ...state.entityShift,
      allIds: updId,
      byId: {
        ...byId,
        ...normalize,
      }
    },
  });
};

const createShiftTypeSuccess = (action) => (state) => {
  const { data } = action.result;

  return ({
    ...state,
    entityShift: {
      allIds: [
        ...state.entityShift.allIds,
        data.attributes.id,
      ],
      byId: {
        ...state.entityShift.byId,
        [data.attributes.id]: data.attributes,
      },
    }
  });
};

const updateShiftTypeSuccess = (action) => (state) => {
  const { data } = action.result;

  return ({
    ...state,
    entityShift: {
      ...state.entityShift,
      byId: {
        ...state.entityShift.byId,
        [data.attributes.id]: data.attributes,
      },
    }
  });
};

const deleteShiftTypeSuccess = (action) => (state) => {
  const { byId, allIds } = state.entityShift;
  const filteredAllIds = allIds.filter((id) => id !== action.id);

  const updatedById = { ...byId };
  delete updatedById[action.id];

  return ({
    ...state,
    entityShift: {
      allIds: filteredAllIds,
      byId: updatedById,
    },
  });
};

const loadExpensesSuccess = (action) => (state) => {
  const { data } = action.result;

  return ({
    ...state,
    listExpenses: [...data]
  });
};

const loadRemovalReasonsSuccess = (action) => (state) => {
  const { data } = action.result;
  const preparedData = data.map((el) => el.attributes);

  return ({
    ...state,
    removalReasons: preparedData,
  });
};

const addRemovalReasonsSuccess = (action) => (state) => {
  const { data } = action.result;

  return ({
    ...state,
    removalReasons: [
      ...state.removalReasons,
      data.attributes,
    ]
  });
};

const deleteRemovalReasonsSuccess = (action) => (state) => {
  const { id } = action;
  const { removalReasons } = state;

  const filteredReasons = removalReasons.filter((el) => el.id !== id);
  return ({
    ...state,
    removalReasons: filteredReasons
  });
};

const updateRemovalReasonsSuccess = (action) => (state) => {
  const { data } = action.result;
  const { removalReasons } = state;

  const index = removalReasons.findIndex((el) => el.id === +data.id);
  const updatedReasons = [...removalReasons];
  updatedReasons[index] = data.attributes;
  return ({
    ...state,
    removalReasons: updatedReasons
  });
};

const loadTagsSuccess = (action) => (state) => {
  const { data } = action.result;
  const tags = data.map((item) => item.attributes);
  return ({
    ...state,
    tags,
  });
};

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD_PROPERTIES]: (state) => updateRequestOnLoad(true)(state),
  [LOAD_PROPERTIES_SUCCESS]: (state, action) => flowRight(
    updateRequestOnLoadSuccess(false),
    updateSettingsOnLoad(action),
  )(state),
  [LOAD_PROPERTIES_FAIL]: (state, action) => updateRequestOnLoadFail(action, false)(state),
  [ADD_PROPERTY_SUCCESS]: (state, action) => addPropertySuccess(action)(state),
  [UPDATE_PROPERTY_SUCCESS]: (state, action) => updatePropertySuccess(action)(state),
  [REMOVE_PROPERTY_SUCCESS]: (state, action) => removePropertySuccess(action)(state),
  [UPDATE_PROPERTY_POSITION_SUCCESS]: (state, action) => updatePositionPropertySuccess(action)(state),
  [LOAD_EXPENSES_SUCCESS]: (state, action) => loadExpensesSuccess(action)(state),
  [LOAD_SHIFT_TYPES_SUCCESS]: (state, action) => loadShiftTypesSuccess(action)(state),
  [CREATE_SHIFT_TYPE_SUCCESS]: (state, action) => createShiftTypeSuccess(action)(state),
  [UPDATE_SHIFT_TYPE_SUCCESS]: (state, action) => updateShiftTypeSuccess(action)(state),
  [DELETE_SHIFT_TYPE_SUCCESS]: (state, action) => deleteShiftTypeSuccess(action)(state),
  [LOAD_REMOVAL_REASONS_SUCCESS]: (state, action) => loadRemovalReasonsSuccess(action)(state),
  [ADD_REMOVAL_REASON_SUCCESS]: (state, action) => addRemovalReasonsSuccess(action)(state),
  [UPDATE_REMOVAL_REASON_SUCCESS]: (state, action) => updateRemovalReasonsSuccess(action)(state),
  [DELETE_REMOVAL_REASON_SUCCESS]: (state, action) => deleteRemovalReasonsSuccess(action)(state),
  [LOAD_TAGS_SUCCESS]: (state, action) => loadTagsSuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
