import isFunction from 'lodash/isFunction';
import { checkItemInArray } from '../../utils/utils';

const LOAD_WORKLOGS = 'redux-ducks/timetracker/LOAD_WORKLOGS';
const LOAD_WORKLOGS_SUCCESS = 'redux-ducks/timetracker/LOAD_WORKLOGS_SUCCESS';
const LOAD_WORKLOGS_FAIL = 'redux-ducks/timetracker/LOAD_WORKLOGS_FAIL';

const INCREASE_COUNT = 'redux-ducks/timetracker/INCREASE_COUNT';

const LOAD_WORKLOG = 'redux-ducks/timetracker/LOAD_WORKLOG';
const LOAD_WORKLOG_SUCCESS = 'redux-ducks/timetracker/LOAD_WORKLOG_SUCCESS';
const LOAD_WORKLOG_FAIL = 'redux-ducks/timetracker/LOAD_WORKLOG_FAIL';

const UPDATE = 'redux-ducks/timetracker/UPDATE';
const UPDATE_SUCCESS = 'redux-ducks/timetracker/UPDATE_SUCCESS';
const UPDATE_FAIL = 'redux-ducks/timetracker/UPDATE_FAIL';

const APPROVE = 'redux-ducks/timetracker/APPROVE';
const APPROVE_SUCCESS = 'redux-ducks/timetracker/APPROVE_SUCCESS';
const APPROVE_FAIL = 'redux-ducks/timetracker/APPROVE_FAIL';

const CHECK_AGREEMENT = 'redux-ducks/timetracker/CHECK_AGREEMENT';
const CHECK_AGREEMENT_SUCCESS = 'redux-ducks/timetracker/CHECK_AGREEMENT_SUCCESS';
const CHECK_AGREEMENT_FAIL = 'redux-ducks/timetracker/CHECK_AGREEMENT_FAIL';

const UPLOAD_DOCUMENT = 'redux-ducks/timetracker/UPLOAD_DOCUMENT';
const UPLOAD_DOCUMENT_SUCCESS = 'redux-ducks/timetracker/UPLOAD_DOCUMENT_SUCCESS';
const UPLOAD_DOCUMENT_FAIL = 'redux-ducks/timetracker/UPLOAD_DOCUMENT_FAIL';

const REMOVE_DOCUMENT = 'redux-ducks/timetracker/REMOVE_DOCUMENT';
const REMOVE_DOCUMENT_SUCCESS = 'redux-ducks/timetracker/REMOVE_DOCUMENT_SUCCESS';
const REMOVE_DOCUMENT_FAIL = 'redux-ducks/timetracker/REMOVE_DOCUMENT_FAIL';

const SEND = 'redux-ducks/timetracker/SEND';
const SEND_SUCCESS = 'redux-ducks/timetracker/SEND_SUCCESS';
const SEND_FAIL = 'redux-ducks/timetracker/SEND_FAIL';

const UPDATE_TAGS = 'redux-ducks/timetracker/UPDATE_TAGS';
const UPDATE_TAGS_SUCCESS = 'redux-ducks/timetracker/UPDATE_TAGS_SUCCESS';
const UPDATE_TAGS_FAIL = 'redux-ducks/timetracker/UPDATE_TAGS_FAIL';

const SET_BOOKMARK = 'redux-ducks/timetracker/SET_BOOKMARK';
const SET_BOOKMARK_SUCCESS = 'redux-ducks/timetracker/SET_BOOKMARK_SUCCESS';
const SET_BOOKMARK_FAIL = 'redux-ducks/timetracker/SET_BOOKMARK_FAIL';

const DELETE_BOOKMARK = 'redux-ducks/timetracker/DELETE_BOOKMARK';
const DELETE_BOOKMARK_SUCCESS = 'redux-ducks/timetracker/DELETE_BOOKMARK_SUCCESS';
const DELETE_BOOKMARK_FAIL = 'redux-ducks/timetracker/DELETE_BOOKMARK_FAIL';

const DELETE_EXPENSES = 'redux-ducks/timetracker/DELETE_EXPENSES';
const DELETE_EXPENSES_SUCCESS = 'redux-ducks/timetracker/DELETE_EXPENSES_SUCCESS';
const DELETE_EXPENSES_FAIL = 'redux-ducks/timetracker/DELETE_EXPENSES_FAIL';

const CREATE_EXPENSE = 'redux-ducks/timetracker/CREATE_EXPENSE';
const CREATE_EXPENSE_SUCCESS = 'redux-ducks/timetracker/CREATE_EXPENSE_SUCCESS';
const CREATE_EXPENSE_FAIL = 'redux-ducks/timetracker/CREATE_EXPENSE_FAIL';

const UPDATE_EXPENSES = 'redux-ducks/timetracker/UPDATE_EXPENSES';
const UPDATE_EXPENSES_SUCCESS = 'redux-ducks/timetracker/UPDATE_EXPENSES_SUCCESS';
const UPDATE_EXPENSES_FAIL = 'redux-ducks/timetracker/UPDATE_EXPENSES_FAIL';

const SET_QUERY = 'redux-duck/timetracker/SET_QUERY';
const SET_DEFAULT = 'redux-ducks/timetracker/SET_DEFAULT';

const initialState = {
  entities: {
    workers: {
      allIds: [],
      byId: {},
    },
    worklogs: {
      allIds: [],
      byId: {},
    },
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    },
  },
  countRequest: 0,
  search: '',
};

/**
 * ACTIONS
 */

/**
 * Load worklogs
 */
const loadWorklogs = (params, count) => ({
  types: [LOAD_WORKLOGS, LOAD_WORKLOGS_SUCCESS, LOAD_WORKLOGS_FAIL],
  promise: (client) => client.get(`v3/planners/worklogs/workers?${params}`),
  count,
});

/**
 * Update worklog
 * @param {object} data
 * @param {number} id worklogId
 */
export const updateWorklog = (data, id, include = '') => ({
  types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) => client.patch(`v3/planners/worklogs/${id}?${include}`, { data }),
});

/**
 * Approve worklogs
 * @param {object} data
 */
export const approveWorklogs = (data) => ({
  types: [APPROVE, APPROVE_SUCCESS, APPROVE_FAIL],
  promise: (client) => client.post('v3/planners/worklogs/approve', { data }),
});

/**
 * Upload document
 * @param {object} data
 */
export const uploadDocument = (data) => ({
  types: [UPLOAD_DOCUMENT, UPLOAD_DOCUMENT_SUCCESS, UPLOAD_DOCUMENT_FAIL],
  promise: (client) => client.patch(`v3/planners/worklogs/${data.id}/document?include=documents`, { data })
});

/**
 * Remove photo
 * @param {object} data
 */
export const removeDocument = (data) => ({
  types: [REMOVE_DOCUMENT, REMOVE_DOCUMENT_SUCCESS, REMOVE_DOCUMENT_FAIL],
  promise: (client) => client.del(`v3/planners/worklogs/${data.id}/document`, { data }),
  data,
});

/**
 * Check agreement
 * @param {object} data
 */
export const checkAgreement = (worklogId) => ({
  types: [CHECK_AGREEMENT, CHECK_AGREEMENT_SUCCESS, CHECK_AGREEMENT_FAIL],
  promise: (client) => client.get(`v3/planners/worklogs/${worklogId}/billing_agreement`),
});

export const saveQuery = (search) => ({
  type: SET_QUERY,
  search,
});

export const sendMessage = (data) => ({
  types: [SEND, SEND_SUCCESS, SEND_FAIL],
  promise: (client) => client.post('worklog_comments', { data }),
  data,
});

/**
 * Delete expenses
 */
export const deleteExpenses = (data) => ({
  types: [DELETE_EXPENSES, DELETE_EXPENSES_SUCCESS, DELETE_EXPENSES_FAIL],
  promise: (client) => client.del(`v3/planners/expenses/${data.expense_id}`, { data })
});

/**
 * Update expenses
 */
export const updateExpenses = (data) => ({
  types: [UPDATE_EXPENSES, UPDATE_EXPENSES_SUCCESS, UPDATE_EXPENSES_FAIL],
  promise: (client) => client.patch(`v3/planners/expenses/${data.expense_id}`, { data })
});

/**
 * Create expenses
 */
export const createExpense = (data) => ({
  types: [CREATE_EXPENSE, CREATE_EXPENSE_SUCCESS, CREATE_EXPENSE_FAIL],
  promise: (client) => client.post('v3/planners/expenses', { data })
});

/**
 * Set bookmark
 */
export const setBookmark = (id) => ({
  types: [SET_BOOKMARK, SET_BOOKMARK_SUCCESS, SET_BOOKMARK_FAIL],
  promise: (client) => client.post(`v3/planners/worklogs/${id}/bookmark`),
  id,
});

/**
 * Delete bookmark
 */
export const deleteBookmark = (id) => ({
  types: [DELETE_BOOKMARK, DELETE_BOOKMARK_SUCCESS, DELETE_BOOKMARK_FAIL],
  promise: (client) => client.del(`v3/planners/worklogs/${id}/bookmark`),
  id,
});

export const updateTags = (data) => ({
  types: [UPDATE_TAGS, UPDATE_TAGS_SUCCESS, UPDATE_TAGS_FAIL],
  promise: (client) => client.patch(`v3/planners/worklogs/${data.id}/tag_links`, { data }),
  data,
});

export function setDefault() {
  return {
    type: SET_DEFAULT
  };
}

export const loadWorkers = (params) => (dispatch, getState) => {
  // save number of requests
  const count = getState().timetracker.countRequest + 1;
  dispatch({ type: INCREASE_COUNT, count });

  return dispatch(loadWorklogs(params, count));
};

/**
 * Get worklog by id
 * @param {number} id worklog_id
 */
export const getWorklogById = (id, include) => ({
  types: [LOAD_WORKLOG, LOAD_WORKLOG_SUCCESS, LOAD_WORKLOG_FAIL],
  promise: (client) => client.get(`v3/planners/worklogs/${id}?${include}`)
});

/**
 * REDUCERS
 */

const loadWorklogsSuccess = (action) => (state) => {
  const { data, included, meta } = action.result;

  if (state.countRequest > action.count) {
    return ({
      ...state,
    });
  }

  const normalizeWorker = {};
  const normalizeWorklogsIds = [];

  const workersIds = data.map((worker) => {
    const worklogsIds = worker.relationships.worklogs.data.map((worklog) => {
      if (normalizeWorklogsIds.indexOf(worklog.id) === -1) {
        normalizeWorklogsIds.push(+worklog.id);
      }

      return +worklog.id;
    });

    const workerObject = {
      ...worker.attributes,
      worklogsIds,
    };

    normalizeWorker[worker.attributes.id] = workerObject;
    return worker.attributes.id;
  });

  const normalizeWorklogs = {};
  const filteredWorklog = included.filter((item) => item.type === 'worklog');

  filteredWorklog.forEach((item) => {
    const tagsIds = item.relationships.tags.data.map((tag) => +tag.id);

    normalizeWorklogs[item.id] = {
      ...item.attributes,
      tagsIds,
    };
  });

  return {
    ...state,
    entities: {
      ...state.entities,
      workers: {
        allIds: workersIds,
        byId: normalizeWorker,
      },
      worklogs: {
        allIds: normalizeWorklogsIds,
        byId: normalizeWorklogs,
      },
      pagination: meta.pagination,
    }
  };
};

const loadWorklogSuccess = (action) => (state) => {
  const { data, included } = action.result;
  const { allIds } = state.entities.worklogs;

  if (!checkItemInArray(allIds, data.attributes.id)) {
    return {
      ...state,
    };
  }

  const tagsIds = included.filter((el) => el.type === 'tag').map((el) => el.attributes.id);
  return {
    ...state,
    entities: {
      ...state.entities,
      worklogs: {
        ...state.entities.worklogs,
        byId: {
          ...state.entities.worklogs.byId,
          [data.attributes.id]: {
            ...state.entities.worklogs.byId[data.attributes.id],
            ...data.attributes,
            tagsIds,
          }
        }
      },
    },
  };
};

const updateWorklogSuccess = (action) => (state) => {
  const { data } = action.result;
  const { allIds } = state.entities.worklogs;

  if (!checkItemInArray(allIds, data.attributes.id)) {
    return {
      ...state,
    };
  }

  return {
    ...state,
    entities: {
      ...state.entities,
      worklogs: {
        ...state.entities.worklogs,
        byId: {
          ...state.entities.worklogs.byId,
          [data.attributes.id]: {
            ...state.entities.worklogs.byId[data.attributes.id],
            ...data.attributes,
          }
        }
      },
    },
  };
};

const approveWorklogSuccess = (action) => (state) => {
  const { data } = action.result;
  const { allIds, byId } = state.entities.worklogs;

  data?.forEach((worklog) => {
    if (!checkItemInArray(allIds, +worklog.id)) {
      return;
    }

    byId[worklog.id] = {
      ...byId[worklog.id],
      status: worklog.attributes.status,
    };
  });

  return {
    ...state,
    entities: {
      ...state.entities,
      worklogs: {
        ...state.entities.worklogs,
        byId
      },
    },
  };
};

const sendCommentSuccess = (action) => (state) => {
  const { byId } = state.entities.worklogs;
  const { data } = action;
  const current = byId[data.worklog_id];

  return ({
    ...state,
    entities: {
      ...state.entities,
      worklogs: {
        ...state.entities.worklogs,
        byId: {
          ...byId,
          [current.id]: {
            ...current,
            commentable_count: ++current.commentable_count,
          }
        }
      },
    },
  });
};

const setBookmarkSuccess = (action) => (state) => {
  const { byId } = state.entities.worklogs;
  const { id } = action;
  const current = byId[id];

  return ({
    ...state,
    entities: {
      ...state.entities,
      worklogs: {
        ...state.entities.worklogs,
        byId: {
          ...byId,
          [current.id]: {
            ...current,
            bookmark: true,
          }
        }
      },
    },
  });
};
const deleteBookmarkSuccess = (action) => (state) => {
  const { byId } = state.entities.worklogs;
  const { id } = action;
  const current = byId[id];

  return ({
    ...state,
    entities: {
      ...state.entities,
      worklogs: {
        ...state.entities.worklogs,
        byId: {
          ...byId,
          [current.id]: {
            ...current,
            bookmark: false,
          }
        }
      },
    },
  });
};

const updateTagsSuccess = (action) => (state) => {
  const { byId } = state.entities.worklogs;
  const { data } = action;

  const tagsIds = action.data.worklog.tags_ids || [];
  const current = byId[data.id];

  return ({
    ...state,
    entities: {
      ...state.entities,
      worklogs: {
        ...state.entities.worklogs,
        byId: {
          ...byId,
          [current.id]: {
            ...current,
            tagsIds,
          }
        }
      },
    },
  });
};

const setCountSuccess = (action) => (state) => ({
  ...state,
  countRequest: action.count
});

const setQuerySuccess = (action) => (state) => ({
  ...state,
  search: action.search,
});

const setDefaultSuccess = () => ({
  ...initialState,
});

const actionsLookup = {
  [LOAD_WORKLOGS_SUCCESS]: (state, action) => loadWorklogsSuccess(action)(state),
  [LOAD_WORKLOG_SUCCESS]: (state, action) => loadWorklogSuccess(action)(state),
  [UPDATE_EXPENSES_SUCCESS]: (state, action) => updateWorklogSuccess(action)(state),
  [CREATE_EXPENSE_SUCCESS]: (state, action) => updateWorklogSuccess(action)(state),
  [UPDATE_SUCCESS]: (state, action) => updateWorklogSuccess(action)(state),
  [APPROVE_SUCCESS]: (state, action) => approveWorklogSuccess(action)(state),
  [SEND_SUCCESS]: (state, action) => sendCommentSuccess(action)(state),
  [UPDATE_TAGS_SUCCESS]: (state, action) => updateTagsSuccess(action)(state),
  [SET_BOOKMARK_SUCCESS]: (state, action) => setBookmarkSuccess(action)(state),
  [DELETE_BOOKMARK_SUCCESS]: (state, action) => deleteBookmarkSuccess(action)(state),
  [INCREASE_COUNT]: (state, action) => setCountSuccess(action)(state),
  [SET_QUERY]: (state, action) => setQuerySuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
